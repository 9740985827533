<template>
  <!-- 融资管理 -->
  <div class="financeManagementBox">
    <div>
      <div class="page-container-table searchBox">
        <div class="applyTime">
          <span class="applyTime-text">申请时间:</span>
          <el-button
            v-for="(item, index) in tabsList"
            :key="index"
            :type="tabsActive == index ? 'primary' : 'text'"
            size="mini"
            round
            @click="getTimer(item, index)"
          >
            {{ item.lable }}
          </el-button>
          <span class="financingStatus-text">自定义</span>
          <el-date-picker
            v-model="formInline.timeArr"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getPickerTimer"
          />
        </div>
        <div class="financingStatus">
          <span class="financingStatus-text">融资状态:</span>
          <el-button
            v-for="(item, index) in tabsList2"
            :key="index"
            round
            :type="tabsActive2 == index ? 'primary' : 'text'"
            size="mini"
            @click="getTabIndex(item, index)"
          >
            {{ item.lable }}
          </el-button>
        </div>

        <!-- 搜索筛选 -->
        <FormSearch
          :getdata="getdata"
          :form-inline="formInline"
          :form-item-arr="formItemArr"
          class="form_Search"
          @resetFormInline="resetFormInline"
        />
      </div>
      <!-- 表格  分页 -->
      <div class="page-container-table">
        <Table
          :operation-button-width="236"
          :operation-button="operationButton"
          :item-data="itemData"
          :list-data="listData"
          class="table-but"
        >
          <template #financeStatus="scoped">
            <div class="stateBox">
              <i
                :class="[
                  scoped.row.financeStatus === '5' || scoped.row.financeStatus === '99'
                    ? 'color1'
                    : '',
                  scoped.row.financeStatus === '4' ? 'color2' : '',
                  scoped.row.financeStatus === '6' ? 'color3' : '',
                ]"
              />
              <span>{{ ($store.getters.getDictionaryItem("SCF_FINANCE_SCF_STATUS").find((item) => item.dictId === scoped.row.financeStatus) || {}).dictName }}</span>
              <span
                v-if="
                  (scoped.row.financeStatus === '338' || scoped.row.financeStatus === '339') &&
                    scoped.row.timer > 0
                "
                class="overdue"
              >已逾期</span>
            </div>
          </template>
        </Table>
        <Pagination
          :params="formInline"
          :total="total"
          :in-article="listData.length"
          :get-data-list="getdata"
        />
      </div>

      <!-- <button @click="tiao">详情</button> -->
    </div>
  </div>
</template>
<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { financeManagementList } from '@/api/orderFinancing.js'
import { timestampToTime, distanceDays } from '@/utils/util'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      tabsActive: '',
      tabsActive2: '',
      tabsList: [
        { lable: '今天', val: 1 },
        { lable: '最近七天', val: 2 },
        { lable: '最近一个月', val: 3 },
        { lable: '最近三个月', val: 4 }
      ],
      tabsList2: [
        { lable: '全部', val: '' },
        { lable: '待支付', val: 99 },
        { lable: '审核中', val: 1 },
        { lable: '待签署', val: 2 },
        { lable: '待放款', val: 4 },
        { lable: '待还款', val: 5 },
        { lable: '部分还款', val: 6 },
        { lable: '已结清', val: 7 },
        { lable: '已拒绝', val: 102 },
        { lable: '已取消', val: 100 }
      ],
      formItemArr: [
        {
          type: 'input',
          label: '融资方',
          value: 'applicationCmpName',
          width: '180'
        },
        { type: 'input', label: '资金方', value: 'funderName', width: '180' },
        {
          type: 'radio',
          label: '是否逾期',
          prop: 'contractNo',
          pLabel: 'dictName',
          pValue: 'dictId',
          width: '180',
          child: [
            { dictName: '是', dictId: 1 },
            { dictName: '否', dictId: 0 }
          ]
        }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.addRow },
        { val: 'audit', bType: 'primary', label: '审核', handleEvent: this.addRow }
      ],
      itemData: [
        { label: '融资编号', prop: 'financeNo', width: '160' },
        { label: '关联订单号', prop: 'orderNo', width: '160' },
        { label: '融资方', prop: 'applicationCmpName', width: '160' },
        { label: '资金方', prop: 'funderName', width: '160' },
        { label: '融资金额', prop: 'applyFinancingAmount', width: '160' },
        { label: '融资利率', prop: 'interestRate', width: '160' },
        { label: '还款截止日', prop: 'repaymentDeadline', width: '160' },
        { label: '申请时间', prop: 'createTime', width: '160' },
        { label: '融资状态', prop: 'financeStatus', width: '160', child: this.$store.getters.getDictionaryItem('SCF_FINANCE_STATUS') }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0
    }
  },
  methods: {
    // 时间选项的获取时间
    getPickerTimer(arr) {
      if (arr && arr.length > 0) {
        this.formInline.applicationTimeEnd = arr[1]
        this.formInline.applicationTimeStart = arr[0]
        return
      }
      this.formInline.applicationTimeEnd = ''
      this.formInline.applicationTimeStart = ''
    },
    // 详情
    addRow(row) {
      this.$store.commit('setResourceByTaskObj', { ...row })
      this.$router.push({
        path: '/orderFinancing/financeManagementDetails',
        query: { id: row.id }
      })
    },
    // 点击重置按钮
    resetFormInline() {
      this.tabsActive = ''
      this.tabsActive2 = ''
      this.formInline.applicationTimeEnd = ''
      this.formInline.applicationTimeStart = ''
      this.formInline.timeArr = []
    },
    // 点击时间跟状态
    getTimer(row, index) {
      this.formInline.dateType = row.val
      this.tabsActive = index
    },
    getTabIndex(row, index) {
      this.formInline.status = row.val
      this.tabsActive2 = index
    },
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      // 获取融资管理
      financeManagementList(this.formInline, res => {
        res.data.pageData.forEach((item, index) => {
          if (item.repaymentDeadline) {
            item.timer = distanceDays(item.repaymentDeadline)
          }
          if (item.activiteId && item.currentNodeId) {
            switch (item.auditResult) {
              case '-1':
                item.audit = false
                break
              case '99':
                item.audit = false
                break
              default:
                item.audit = true
                break
            }
          } else {
            item.audit = false
          }
          switch (item.loanInterestRateUnit) {
            case 1:
              item.interestRate = item.interestRate + '%'
              break

            case 2:
              item.interestRate = item.interestRate + '%/年'
              break
            case 3:
              item.interestRate = item.interestRate + '元/笔'
              break
          }
          // switch (item.status) {
          //   case 1:
          //     item.statusName = '审核中'
          //     break
          //   case 2:
          //     item.statusName = '待融资方签署'
          //     break
          //   case 3:
          //     item.statusName = '待担保方签署'
          //     break
          //   case 4:
          //     item.statusName = '待放款'
          //     break
          //   case 5:
          //     item.statusName = '待还款'
          //     break
          //   case 6:
          //     item.statusName = '部分还款'
          //     break
          //   case 7:
          //     item.statusName = '已结清'
          //     break
          //   case 99:
          //     item.statusName = '待支付'
          //     break
          //   case 100:
          //     item.statusName = '已取消'
          //     break
          //   case 102:
          //     item.statusName = '已拒绝'
          //     break
          //   default:
          //     break
          // }
          item.createTime = timestampToTime(item.createTime)
          // res.data.pageData[index].createTime = timestampToTime(
          //   res.data.pageData[index].createTime
          // )
        })
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.financeManagementBox {
  box-shadow: none !important;
  .stateBox {
    display: flex;
    align-items: center;
    span {
      margin-right: 6px;
    }
    .overdue {
      width: 44px;
      height: 21px;
      border-radius: 4px;
      opacity: 1;
      background: rgba(226, 90, 74, 0.08);
      color: #e25a4a;
      font-size: 12px;
      text-align: center;
    }
    i {
      height: 6px;
      width: 6px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 4px;
      background-color: #1363e8;
    }
    .color1 {
      background-color: #ee9c2d;
    }
    .color2 {
      background-color: #e25a4a;
    }
    .color3 {
      background-color: #dcdcdc;
    }
  }
  .searchBox {
    margin-bottom: 17px;
    padding-bottom: 0;
  }
  .applyTime,
  .financingStatus {
    .applyTime-text,
    .financingStatus-text {
      // height: 16px;
      font-size: 14px;
      line-height: 49px;
      margin-right: 10px;
    }
    .pans {
      background-color: navy;
    }
  }
  /deep/ .el-button--primary {
    width: 100px;
  }
  /deep/.table-but .el-button--primary {
    width: 56px !important;
  }
  .demo-tabs {
    margin-bottom: 10px;
    width: 90%;
    .pans {
      background-color: navy;
    }
  }

  .form-search {
    box-shadow: none !important;
  }
  ::v-deep .el-tabs__nav {
    z-index: 0 !important;
  }
  /deep/ .el-button--text {
    color: #000 !important;
    width: 100px;
  }
}
</style>
